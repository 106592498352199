<template>
  <div class="page-404">
    <HeaderParallax image="/app_images/404.webp" :options="{opacity: '60', background: 'black'}" :header="$t('404.title')"/>
    <spacer height="30px"/>
    <v-container :fluid="$vuetify.breakpoint.xsOnly">
      <div class="text-center font-20">
        <vue-markdown> {{ $t('404.intro') }} </vue-markdown>
      </div>
      <v-row class="align-center justify-center">
        <v-col cols="12" sm="8">
          <search/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import HeaderParallax from '@/app/common/components/header-parallax'
import Search from '@/app/modules/estates/components/search/quick-search'

export default {

  name: 'NotFound',

  metaInfo () {
    return {
      title: '404 - Rbns page not found',
      meta: [
        {
          name: 'robots',
          content: 'noindex'
        }
      ]
    }
  },

  components: {
    HeaderParallax,
    Search
  }
}
</script>

<style lang="scss">
</style>
