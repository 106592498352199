<template>
  <v-parallax class="header-parallax"
              :src="headerImage()"
              :height="headerImageHight()">

    <clean-header :header="header" :subheader="subheader" nobg/>
  </v-parallax>
</template>

<script>
import CleanHeader from './header-clean'

export default {

  name: 'HeaderParallax',

  props: {
    image: {
      type: String,
      required: true
    },

    options: {
      type: Object,
      default: () => {}
    },

    header: String,
    subheader: String
  },

  components: {
    CleanHeader
  },

  methods: {
    headerImageHight () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 250
        case 'lg':
          return 450
        default:
          return 350
      }
    },

    headerImage () {
      return this.$cloudinary.url(this.image, { width: window.innerWidth, height: this.headerImageHight() * 1.5, crop: 'crop', gravity: 'center', ...this.options })
    }
  }
}
</script>

<style lang="scss">

  .header-parallax {
    background-color: var(--v-secondary-base);
    box-shadow: 0 -10px 18px 4px var(--v-secondary-base);

    .v-parallax__content {
      padding: 0;
    }
  }

</style>
